<template>
  <layout name="EmptyLayout">
    <h1>About Page</h1>
  </layout>
</template>

<script>
import Layout from '@/layouts/Layout';

export default {
  name: 'AboutView',
  components: {
    Layout
  }
}
</script>
